export const ar = {
  dashboard: 'لوحة التحكم',
  statistics: 'إحصائيات',
  project: {
    title: 'المشاريع',
    all_project: 'كل المشاريع',
    add_project: 'إضافة مشروع',
    my_projects: 'مشاريعي',
    details: 'تفاصيل المشروع',
    edit: 'تحرير المشروع',
    edit_request: 'طلب تعديل المشروع',
    edit_project: 'تحرير المشروع',
    delete_project: 'حذف المشروع',
    project_requests: 'طلبات المشاريع',
  },
  campaign: {
    title: 'الحملات',
    all_campaign: 'كل الحملات',
    add_campaign: 'أضف حملة',
    my_campaigns: 'حملاتي',
    edit_campaign: 'تحرير الحملة',
    payment_history: 'سجل الدفعات',
    task_volunteers: 'المهمة والمتطوعين',
    new_ticket: 'تذكرة جديدة',
    delete_campaign: 'حذف الحملة',
    campaign_discussion: 'مناقشة الحملة',
    latest_comments: 'احدث التعليقات',
    anonymous_user: 'مجهول',
    no_comments: 'لا يوجد تعليقات',
    reply: 'رد',
    delete: 'حذف',
    details: 'تفاصيل الحملة',
    edit_request: 'طلب تحرير الحملة',
    donor_list_campaign: 'قائمة المانحين للحملة',
    donor_list_project: 'قائمة المانحين للمشروع',
    vendor_list_campaign: 'قائمة الحملات',
    vendor_favorites_campaign: 'الحملات المفضلة',
    status: 'الحالة الحملة',
    vendor_requests: 'طلبات المقاولون',
    detail_vendor_request: 'طلبات المقاولون التفصيلية',
    vendor_lists: 'قوائم البائعين',
    chat_with_vendor: 'الدردشة مع البائع',
    send_back_requests: 'الطلبات المعادة',
    send_it_back_to: 'أرسلها إلى',
    detail_send_back_requests: 'التفاصيل إرسال طلبات العودة',
  },
  marketplace: {
    title: 'المتجر',
    all_item: 'كل المنتجات',
    add_item: 'اضافة عنصر',
    details: 'تفاصيل عنصر المشروع',
    edit_item: 'تعديل عنصر',
    edit_request_item: 'تحرير الطلب',
    donor_list_item: 'قائمة المانحين لعناصر المشروع',
  },
  donors: {
    title: 'المتبرعين',
    add_donor: 'اضافة مانح',
    all_donor: 'جميع المانحين',
    donor_overview: 'نظرة عامة على المانحين',
    update_donor: 'تعديل مانح',
  },
  vendors: {
    title: 'المقاولون',
    all_vendor: 'كل المقاولون',
    vendor_requests: 'طلبات المقاولون',
    add_vendor: 'إضافة مقاول جديد',
    detail_vendor: 'تفاصيل البائع',
    detail_requests_vendor: 'طلب بائع التفاصيل',
    milestone_progress: 'رفع منجز',
    milestone_invoices: 'الفواتير التقدم المعلم',
    payment_history_progress: 'تقدم سجل الدفعات',
    edit: 'تحرير البائع',
    add_send_back_request_campaign: 'أرسل مرة أخرى إلى المشغل',
    nonprofit_organizations: 'الجمعيات والشركات غير الربحية',
  },
  operators: {
    title: 'مكاتب إدارة المشاريع',
    all_operator: 'مكاتب إدارة المشاريع',
    add_operator: 'إضافة مكتب إدارة المشاريع',
    update_operator: 'تحديث مكتب إدارة المشاريع',
    detail_operator: 'تفاصيل مكتب إدارة المشاريع',
  },
  evaluators: {
    title: 'المقيمون',
    maintenance_requests: `تقارير الصيانة للمقيمين`,
    evaluators_request: `طلب المقيم`,
    all_evaluator: 'كل المقيمين',
    add_evaluator: 'اضافة مقيم',
    update_evaluator: 'تعديل مقيم',
    details: `معلومات المقيم`,
    chat_with_super_admin: 'التحدث الى المسؤول',
    chat_with_evaluator: 'التحدث الى المقيِّم',
  },
  mosques: {
    add_mosque: 'اضافة مسجد جديد للصيانة',
  },
  tickets: {
    title: 'التذاكر',
    all_ticket: 'كل التذاكر',
    detail_ticket: 'تذكرة التفاصيل',
  },
  directories: 'الدلائل',
  maintenance_request: 'طلب صيانة',
  community: 'مجتمع',
  blogs: {
    title: 'المدونات',
  },
  items: 'المنتجات',
  request: 'طلب',
  more_options: 'المزيد من الخيارات',
  select_your_options: 'حدد خياراتك',
  operator_page: 'صفحة المشغل',
  campaign_public_page: 'الصفحة العامة للحملة',
  parent_project_page: 'صفحة مشروع الوالدين',
  project_public_page: 'الصفحة العامة للمشروع',
  logout: 'تسجيل خروج',
  settings: {
    title: 'الإعدادات',
    account_info: 'معلومات الحساب',
    security_settings: 'اعدادات الامان',
    my_cards: 'بطاقاتي',
    public_profile: 'الإعدادات الملف الشخصي العام',
    profile_settings: 'الإعدادات الملف الشخصي',
    delete_account: 'حذف الحساب',
  },
  emails: {
    title: 'الايميلات',
    details: 'تفاصيل الايميل',
  },
  entity_area: {
    title: 'المناطق الإدارية',
    governorates: 'المناطق الإدارية',
    regions: 'المناطق',
  },
  wallet_management: 'كبار المانحين',
};
